import React from 'react';
import PageLayout from '../components/PageLayout';

function Home() {
  return (
    <PageLayout>
      <div style={{height: '200vh'}}>Hello</div>
    </PageLayout>
  );
}

export default Home;
